<template>
  <div>
    <h2>示例页面</h2>
    <h2>示例组件</h2>
    <div>
      <el-button @click="chooseClass">选择班级</el-button>
      班级数据:{{ JSON.stringify(chooseClassResult) }}
    </div>
    <div>
      <el-button @click="ChooseTeacher">选择老师</el-button>
      班级数据:{{ JSON.stringify(teacherName) }}
    </div>

    <div>性别数据:{{ JSON.stringify(genders) }}</div>

    <div>
      <as-autocompelete
        v-model="studentName"
        :fetch-suggestions="querySearch"
        keyName="name"
        @selectChange="selectChange"
        placeholder="输入学员姓名、手机号搜索"
      >
        <div slot="title">这里是示例111标题</div>
        <template slot="item" slot-scope="row">
          <div style="display: flex">
            <div>{{ row.item.value }}111</div>
            <div style="font-size: 12px">({{ row.item.value }})</div>
          </div>
        </template>
      </as-autocompelete>

      学员姓名：{{ studentName }}
    </div>
    <div>switch示例</div>
    <as-switch active-text="是的" inactive-text="否的" :height="30"></as-switch>
    <div class="service-item-content service-item-taste-content">
      <div class="taste-content">
        <el-button type="primary" size="mini" @click="startVoice">开始转写</el-button>
      </div>
      <div class="start-taste">
        <div class="start-taste-left">
          <el-input
            type="textarea"
            :rows="5"
            placeholder="这里是识别区"
            v-model="voiceCotent"
            v-intelligentRecognition="{style:{right:'50px'}}"
          >
          </el-input>
        </div>
        <div class="output-box" id="result_output"></div>
      </div>
    </div>
    <div class="boxx">
      <div class="box-list">
        <span></span><span></span><span></span><span></span><span></span>
      </div>
      <div class="box-list">
        <span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span>
      </div>
      <div class="box-list">
        <span></span><span></span>
      </div>
    </div>
    <div>
      <el-button type="primary" @click="outboundCall">电话助手</el-button>
      <el-button v-preventReClick="1000">重复点击测试</el-button>
    </div>
  </div>
</template>
<script>
import { hours, genders } from "@/config/index";

export default {
  components: {
  },
  data() {
    return {
      hours,
      genders,
      ajaxMethod: {
        list: "",
        add: "",
        edit: "",
        delete: "",
      },
      chooseClassResult: [],
      teacherName: "",
      studentName: "",
      students: [
        { value: "三全鲜食（北新泾店）", address: "长宁区新渔路144号" },
        {
          value: "Hot honey 首尔炸鸡（仙霞路）",
          address: "上海市长宁区淞虹路661号",
        },
        {
          value: "新旺角茶餐厅",
          address: "上海市普陀区真北路988号创邑金沙谷6号楼113",
        },
        { value: "泷千家(天山西路店)", address: "天山西路438号" },
        {
          value: "胖仙女纸杯蛋糕（上海凌空店）",
          address: "上海市长宁区金钟路968号1幢18号楼一层商铺18-101",
        },
        { value: "贡茶", address: "上海市长宁区金钟路633号" },
        {
          value: "豪大大香鸡排超级奶爸",
          address: "上海市嘉定区曹安公路曹安路1685号",
        },
        {
          value: "茶芝兰（奶茶，手抓饼）",
          address: "上海市普陀区同普路1435号",
        },
        { value: "十二泷町", address: "上海市北翟路1444弄81号B幢-107" },
        { value: "星移浓缩咖啡", address: "上海市嘉定区新郁路817号" },
        { value: "阿姨奶茶/豪大大", address: "嘉定区曹安路1611号" },
      ],
      voiceCotent: "",
    };
  },
  created() { 
  },
  mounted() {
    //this.$Dialog.OutboundCall();
    // this.$Dialog.OutboundCalling();
  },
  methods: {
    outboundCall(){
      this.$Dialog.OutboundCall();
    },
    startVoice() {
      this.$Dialog.SpeechRecognition().then((res) => {
        this.voiceCotent = res;
      });
    },

    //点击某一项的事件
    selectChange(item) { 
    },
    //搜索事件
    querySearch(queryString, cb) {
      if (queryString) {
        cb(this.students.filter((t) => t.value.indexOf(queryString) > -1));
      } else {
        cb(this.students);
      }
    },
    chooseClass() {
      this.$Dialog.ChooseClass().then((res) => {
        this.chooseClassResult = res || [];
      });
    },
    ChooseTeacher() {
      this.$Dialog.ChooseTeacher({ teacherName: "班级1" }).then((res) => {
        this.teacherName = res || "";
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.boxx{
  width: 80%;
  margin: 20px auto;
  .box-list{
    display: flex;
    justify-content: space-between;
    span{
      min-width: 100px;
      height: 100px;
      background-color: royalblue;
      margin-bottom: 10px;
      margin: 10px;
    }
  }
}
</style>